import React from 'react';
import Layout from '../../components/layout/Layout';
import PremiumTransactions from '../../components/analytics/premium/PremiumTransactions';

const PremiumAnalytics = () => {
  return (
    <Layout active="premium-analytics">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">
          Premium Analytics
        </div>
      </div>

      <PremiumTransactions />
    </Layout>
  );
};

export default PremiumAnalytics;
