import axios from 'axios';
import { apiUrl, master_key } from '../../config/settings';
import { unauthorizedError } from './state';

export const getPremiumSettings = () => (dispatch) => {
  return axios
    .get(apiUrl + '/config/all', {
      headers: { Authorization: 'Bearer ' + master_key },
    })
    .then((response) => {
      dispatch({ type: 'GET_PREMIUM_SETTINGS', data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: 'GET_PREMIUM_SETTINGS_ERROR' });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
      return [];
    });
};

export const updatePremiumSetting = (key, value) => (dispatch) => {
  return axios
    .put(
      apiUrl + '/config/' + key,
      { data: value },
      { headers: { Authorization: 'Bearer ' + master_key } }
    )
    .then((response) => {
      dispatch({ type: 'UPDATE_PREMIUM_SETTING' });
      dispatch(getPremiumSettings());
    })
    .catch(function (error) {
      alert('Update ERROR');
      alert(error);
      dispatch({ type: 'UPDATE_PREMIUM_SETTING_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const updateAllPremiumSettings = (settings) => async (dispatch) => {
  try {
    await Promise.all(
      Object.entries(settings).map(([key, value]) =>
        axios.put(
          apiUrl + '/config/' + key,
          { data: value },
          { headers: { Authorization: 'Bearer ' + master_key } }
        )
      )
    );
    dispatch({ type: 'UPDATE_PREMIUM_SETTING' });
    alert('Updated');
    dispatch(getPremiumSettings());
  } catch (error) {
    alert('Update ERROR');
    alert(error);
    dispatch({ type: 'UPDATE_PREMIUM_SETTING_ERROR' });
    if (error.response && error.response.status === 401) {
      dispatch(unauthorizedError());
    }
  }
};
