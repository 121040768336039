import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import DateSelector from '../../DateSelector';
import CustomButton from '../../CustomButton';
import Spinner from '../../Spinner';
import { getPremiumTransactions } from '../../../redux/actions/analytics';
import { formatDate } from '../helper';

const PremiumTransactions = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isFormChanged, setIsFormChanged] = useState(false);

  const transactions = useSelector((state) => state.analytics.premium_transactions);
  const loading = useSelector((state) => state.analytics.premium_transactions_loading);

  const search = useCallback(() => {
    dispatch(getPremiumTransactions(formatDate(startDate), formatDate(endDate)));
    setIsFormChanged(false);
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    search();
  }, [search]);

  const headers = [
    { label: 'User ID', key: 'userid' },
    { label: 'Import ID', key: 'importId' },
    { label: 'Source', key: 'source' },
    { label: 'Product ID', key: 'productId' },
    { label: 'Reason', key: 'reason' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Updated At', key: 'updatedAt' },
    { label: 'Expires At', key: 'expiresAt' },
  ];

  const calculateSubscriptionStats = () => {
    if (!transactions) return null;

    const stats = {
      allNew: 0,
      newSixMonths: 0,
      newMonthly: 0,
      renewedMonthly: 0,
      cancelled: 0,
    };

    transactions.forEach((transaction) => {
      // Calculate subscription length in days
      const createdAt = new Date(transaction.createdAt);
      const expiresAt = new Date(transaction.expiresAt);
      const lengthInDays = Math.round((expiresAt - createdAt) / (1000 * 60 * 60 * 24));

      stats.allNew++;
      if (transaction.reason === 'purchase') {
        if (lengthInDays >= 100) {
          // 6 months subscription
          stats.newSixMonths++;
        } else {
          stats.newMonthly++;
        }
      } else if (transaction.reason === 'renewal') {
        stats.renewedMonthly++;
      } else if (transaction.reason === 'cancellation') {
        stats.allNew--;
        stats.cancelled++;
      }
    });

    return stats;
  };

  const stats = calculateSubscriptionStats();

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => {
          setStartDate(date);
          setIsFormChanged(true);
        }}
        endDateChanged={(date) => {
          setEndDate(date);
          setIsFormChanged(true);
        }}
      />

      <div className="my-8 flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>

        {transactions && transactions.length > 0 && !isFormChanged && (
          <CSVLink
            className="bg-orange text-white p-2 rounded ml-4"
            filename={`PremiumTransactions-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
            data={transactions}
            headers={headers}
          >
            Download this table
          </CSVLink>
        )}
      </div>

      {loading && <Spinner />}

      {!loading && transactions && transactions.length > 0 && (
        <>
          <div className="overflow-x-auto w-full px-8 mb-8">
            <h2 className="text-xl font-bold mb-4">Summary</h2>
            <table className="min-w-full bg-white shadow-md rounded">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left">All new transactions</th>
                  <th className="px-4 py-2 text-left">New 6 months subscriptions</th>
                  <th className="px-4 py-2 text-left">New monthly subscriptions</th>
                  <th className="px-4 py-2 text-left">Renewed monthly subscriptions</th>
                  <th className="px-4 py-2 text-left">Cancelled subscriptions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 text-center">{stats.allNew}</td>
                  <td className="px-4 py-2 text-center">{stats.newSixMonths}</td>
                  <td className="px-4 py-2 text-center">{stats.newMonthly}</td>
                  <td className="px-4 py-2 text-center">{stats.renewedMonthly}</td>
                  <td className="px-4 py-2 text-center">{stats.cancelled}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="overflow-x-auto w-full px-8">
            <h2 className="text-xl font-bold mb-4">Transaction Details</h2>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th>Import ID</th>
                  <th>Source</th>
                  <th>Reason</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction.importId}</td>
                    <td>{transaction.source}</td>
                    <td>{transaction.reason}</td>
                    <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {!loading && (!transactions || transactions.length === 0) && (
        <div className="text-center mt-4">No transactions found for the selected period</div>
      )}
    </div>
  );
};

export default PremiumTransactions;
