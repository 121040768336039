import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/Dropdown';
import { getAllData, update, create } from '../../redux/actions/api';
import { openDeleteModal } from '../../redux/actions/state';
import Checkbox from '../../components/Checkbox';
import ImageUp from '../../components/ImageUp';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import NewButton from '../../components/NewButton';
import CustomButton from '../../components/CustomButton';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import { sortByVisibilityAndOrder } from '../../utils/sort';
import HTMLEditor from '../../components/HTMLEditor';
import MarkdownEditor from '../../components/MarkdownEditor';
import { validateStartEndDates } from '../../utils/date';

const Todo = () => {
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.api.data);
  const deleteType = useSelector((state) => state.state.deleteType);
  const companyList = useSelector((state) => state.analytics.companylist);

  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [tab, setTab] = useState('');
  const [week, setWeek] = useState('');
  const [week2, setWeek2] = useState('');
  const [newCreate, setNew] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});
  const [selectedItem3, setSelectedItem3] = useState({});

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    setSelectedItem2({});
    setSelectedItem3({});
    setTab(null);
    setWeek(null);
    setWeek2(null);
    setType('');
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(apiData).length > 0) {
      setData(apiData.todo);
    }
    if (deleteType === 'todo-category') {
      setWeek(null);
      setNew(true);
      setSelectedItem({
        id: '',
        title: '',
        visible: true,
        order: '',
        data: [],
      });
      setType('');
    }
    if (deleteType === 'todo-article') {
      setWeek(null);
      setNew(true);
      setSelectedItem2({
        id: '',
        title: '',
        summary: '',
        order: '',
        items: [],
        visible: true,
      });
      setType('');
    }
    if (deleteType === 'todo-item') {
      setWeek(null);
      setNew(true);
      setSelectedItem3({
        id: '',
        info: '',
        isButton: 'false',
        isInfoButton: 'false',
        visible: 'true',
        link: '',
        impressionUrl: '',
        title: '',
        order: '',
        product: '',
        companyname: '',
        img: '',
        coverImage: '',
        description: '',
        descriptionHtml: '',
        middlePageButtonText: '',
      });
      setType('');
    }
    setSelectedItem({});
    setSelectedItem2({});
    setSelectedItem3({});
    setTab(null);
    setWeek(null);
    setWeek2(null);
  }, [apiData, deleteType]);

  const save = () => {
    const selected = selectedItem;
    const url = '/todo/' + selectedItem.id;
    dispatch(update(selected, url));
  };
  const save2 = () => {
    const selected = selectedItem2;
    const url = '/todo/updatedata/' + selectedItem.id;
    setSelectedItem2({
      id: '',
      title: '',
      summary: '',
      order: '',
      items: [],
      visible: true,
    });
    dispatch(update(selected, url));
  };
  const save3 = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected2.items.forEach((e, i) => {
      if (e.id === selected.id) {
        selected2.items[i] = selected;
      }
    });

    const dateValidationResult = validateStartEndDates(selected);

    if (!dateValidationResult.valid) return alert(dateValidationResult.error);

    const url = '/todo/updatedata/' + selectedItem.id;
    dispatch(update(selected2, url));
  };
  const createCategory = () => {
    const selected = selectedItem;
    selected.id = Date.now().toString();
    const url = '/todo/';
    setSelectedItem({
      id: '',
      title: '',
      visible: true,
      order: '',
      data: [],
    });
    setNew(false);
    setType('');
    dispatch(create(selected, url));
  };
  const createArticle = () => {
    const selected = selectedItem2;
    selected.id = Date.now().toString();
    const url = '/todo/data/' + selectedItem.id;
    setSelectedItem2({
      id: '',
      title: '',
      summary: '',
      order: '',
      items: [],
      visible: true,
    });
    setNew(false);
    setType('');
    dispatch(update(selected, url));
  };
  const createItems = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected.id = Date.now().toString();
    selected2.items = [...selected2.items, selected];
    const url = '/todo/updatedata/' + selectedItem.id;
    setSelectedItem3({
      id: '',
      info: '',
      isButton: 'false',
      isInfoButton: 'false',
      visible: 'true',
      link: '',
      impressionUrl: '',
      title: '',
      order: '',
      companyname: '',
      product: '',
      img: '',
      coverImage: '',
      description: '',
      descriptionHtml: '',
      middlePageButtonText: '',
      start_date: '',
      end_date: '',
    });
    setNew(false);
    setType('');
    dispatch(update(selected2, url));
  };

  const deleteCategory = () => {
    const selected = selectedItem;
    const url = '/todo/' + selectedItem.id;
    const type = 'todo-category';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };
  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = '/todo/removedata/' + selectedItem.id;
    const type = 'todo-article';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };
  const deleteItems = () => {
    const selected3 = selectedItem3;
    const selected = selectedItem2;
    selected.items = selected.items.filter((item) => item.id !== selected3.id);
    const url = '/todo/updatedata/' + selectedItem.id;
    const type = 'todo-item';
    const title = selected3.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deeplink = useMemo(
    () => `preghello://todo/details/${selectedItem.id}/${selectedItem2.id}`,
    [selectedItem, selectedItem2]
  );

  return (
    <Layout active="todo">
      <div className="w-full">
        <div className="flex flex-row flex-wrap justify-around mx-12">
          <NewButton
            onClick={() => {
              setNew(true);
              setType('first');
              setSelectedItem({
                id: '',
                title: '',
                order: '',
                data: [],
                visible: true,
              });
              setTab(null);
            }}
            title="Add Category"
          />
          {data &&
            data.sort(sortByVisibilityAndOrder()).map((e, i) => {
              return (
                <MenuButton
                  key={`menu-button-${i}`}
                  onClick={() => {
                    const datatorender = {
                      id: e.id,
                      title: e.title ? e.title : '',
                      order: e.order ? e.order : '',
                      data: e.data ? e.data : [],
                      visible: e.visible,
                    };
                    setSelectedItem(datatorender);
                    setTab(e.id);
                    setWeek(null);
                    setNew(false);
                    setSelectedItem2({});
                    setSelectedItem3({});
                  }}
                  active={tab === e.id}
                  visible={e.visible}
                  title={e.title + ' - ' + e.order}
                />
              );
            })}
        </div>
      </div>
      <div className="w-full my-2 flex justify-center">
        {data && tab !== null && data.length > 0 && Object.keys(selectedItem).length > 0 && (
          <div className="flex flex-row flex-wrap w-full mx-12">
            <NewButton
              onClick={() => {
                setNew(true);
                setType('second');
                setSelectedItem2({
                  id: '',
                  title: '',
                  summary: '',
                  order: '',
                  items: [],
                  visible: true,
                });
              }}
              title="Add sub-category"
            />
            {selectedItem.data.sort(sortByVisibilityAndOrder()).map((value, i) => (
              <MenuButton
                key={`menu-button-sub-${i}`}
                onClick={() => {
                  const dataToRender = {
                    id: value.id,
                    title: value.title ? value.title : '',
                    summary: value.summary ? value.summary : '',
                    order: value.order ? value.order : '',
                    items: value.items ? [...value.items].sort(sortByVisibilityAndOrder()) : [],
                    visible: value.visible,
                  };
                  setWeek(value.id);
                  setNew(false);
                  setSelectedItem2(dataToRender);
                  setSelectedItem3({});
                }}
                active={week === value.id}
                title={value.title + ' - ' + value.order}
                visible={value.visible}
              />
            ))}
          </div>
        )}
      </div>
      <div className="w-full my-2 flex justify-center">
        {data &&
          tab !== null &&
          week !== null &&
          data.length > 0 &&
          Object.keys(selectedItem2).length > 0 && (
            <div className="flex flex-row flex-wrap w-full mx-12">
              <NewButton
                onClick={() => {
                  setNew(true);
                  setType('third');
                  setSelectedItem3({
                    id: '',
                    info: '',
                    isButton: 'false',
                    isInfoButton: 'false',
                    product: '',
                    visible: 'false',
                    link: '',
                    impressionUrl: '',
                    title: '',
                    order: '',
                    companyname: '',
                    img: '',
                    coverImage: '',
                    description: '',
                    descriptionHtml: '',
                    middlePageButtonText: '',
                  });
                }}
                title="Add Item"
              />
              {selectedItem2.items &&
                selectedItem2.items.map((value) => (
                  <MenuButton
                    key={`menu-button-sub-sub-${value.id}`}
                    onClick={() => {
                      const dataToRender = {
                        id: value.id,
                        info: value.info ? value.info : '',
                        isButton: value.isButton ? value.isButton : 'false',
                        isInfoButton: value.isInfoButton ?? 'false',
                        visible: value.visible,
                        link: value.link ? value.link : '',
                        impressionUrl: value.impressionUrl ? value.impressionUrl : '',
                        title: value.title ? value.title : '',
                        order: value.order ? value.order : '',
                        product: value.product ?? '',
                        companyname: value.companyname ? value.companyname : '',
                        img: value.img ? value.img : '',
                        coverImage: value.coverImage ? value.coverImage : '',
                        description: value.description ? value.description : '',
                        descriptionHtml: value.descriptionHtml ? value.descriptionHtml : '',
                        middlePageButtonText: value.middlePageButtonText
                          ? value.middlePageButtonText
                          : '',
                        start_date: value.start_date
                          ? moment(value.start_date).format('YYYY-MM-DD')
                          : '',
                        end_date: value.end_date ? moment(value.end_date).format('YYYY-MM-DD') : '',
                      };
                      setWeek2(value.id);
                      setNew(false);
                      setSelectedItem3(dataToRender);
                    }}
                    active={week2 === value.id}
                    title={value.title + ' - ' + value.order}
                    visible={value.visible ? value.visible : 'true'}
                  />
                ))}
            </div>
          )}
      </div>
      {
        // CATEGORY
        ((newCreate && type === 'first') ||
          (Object.keys(selectedItem).length > 0 &&
            Object.keys(selectedItem2).length === 0 &&
            !newCreate)) && (
          <div className="mx-12">
            <TextField type="text" label="Id" name="id" value={selectedItem.id} disabled />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem.title}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.title = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem.order}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.order = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />

            <Checkbox
              name="Visibility"
              selected={selectedItem.visible}
              getValue={(value) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.visible = value;
                setSelectedItem(selectedItemNew);
              }}
              bool
            />

            {newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    setNew(true);
                    setType('first');
                    setSelectedItem({ visible: 'false' });
                    setTab(null);
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton type="submit" className="w-1/4" onClick={() => createCategory()}>
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton red type="submit" className="w-1/4" onClick={() => deleteCategory()}>
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton type="submit" className="w-1/4" onClick={() => save()}>
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
      {
        // ARTICLE
        ((newCreate && type === 'second') ||
          (Object.keys(selectedItem2).length > 0 &&
            Object.keys(selectedItem3).length === 0 &&
            !newCreate)) && (
          <div className="mx-12">
            <TextField type="text" label="Id" name="id" value={selectedItem2.id} disabled />
            <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem2.title}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem2 };
                selectedItemNew.title = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Summary"
              name="summary"
              value={selectedItem2.summary}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem2 };
                selectedItemNew.summary = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem2.order}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem2 };
                selectedItemNew.order = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />

            <Checkbox
              name="Visibility"
              selected={selectedItem2.visible}
              getValue={(value) => {
                const selectedItemNew = { ...selectedItem2 };
                selectedItemNew.visible = value;
                setSelectedItem2(selectedItemNew);
              }}
              bool
            />

            {newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    selectedItem2({});
                    setNew(false);
                    setType('');
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => type === 'second' && createArticle()}
                >
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton red type="submit" className="w-1/4" onClick={() => deleteArticle()}>
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton type="submit" className="w-1/4" onClick={() => save2()}>
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
      {
        // ITEM
        ((newCreate && type === 'third') ||
          (Object.keys(selectedItem3).length > 0 && !newCreate)) && (
          <div className="mx-12">
            {selectedItem3.start_date && selectedItem3.end_date ? (
              <div className="rounded-md bg-lightorange mb-4 p-4">
                <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    This is a scheduled item, link/button and logo visibility might be affected!
                  </p>
                </div>
              </div>
            ) : null}

            <TextField type="text" label="Id" name="id" value={selectedItem3.id} disabled />
            <TextField
              type="text"
              label="Item"
              name="title"
              value={selectedItem3.title}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.title = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <ImageUp
              title="Logo 300x300 or 80x60"
              value={selectedItem3.img}
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.img = '';
                setSelectedItem3(selectedItemNew);
              }}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.img = value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextArea
              type="text"
              label="Info"
              name="info"
              value={selectedItem3.info}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.info = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Link (should start with 'https://')"
              name="link"
              value={selectedItem3.link}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.link = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Impression URL (should start with 'https://')"
              name="impressionUrl"
              value={selectedItem3.impressionUrl}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.impressionUrl = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField label="Company name">
              <Dropdown
                value={selectedItem3.companyname}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={({ value }) => {
                  const selectedItemNew = { ...selectedItem3 };
                  selectedItemNew.companyname = value;
                  setSelectedItem3(selectedItemNew);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Product"
              name="product"
              value={selectedItem3.product}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.product = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem3.order}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.order = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />

            <TextField
              type="text"
              label="Start Date*"
              name="start_date"
              placeholder="2023-01-01"
              required
              value={selectedItem3.start_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.start_date = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="End Date*"
              name="end_date"
              placeholder="2023-01-02"
              required
              value={selectedItem3.end_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.end_date = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />

            <ImageUp
              title="Middle page cover image (1120 x 744) (safe area: 40-44 pixel from the top)"
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.coverImage = '';
                setSelectedItem3(selectedItemNew);
              }}
              value={selectedItem3.coverImage}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.coverImage = value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Middle page button text"
              name="middlePageButtonText"
              value={selectedItem3.middlePageButtonText}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.middlePageButtonText = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Middle page description (html)"
              defaultValue={selectedItem3.descriptionHtml}
              setContents={selectedItem3.descriptionHtml}
              onChange={(html) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.descriptionHtml = html;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <MarkdownEditor
              title="Middle page description (Markdown)"
              value={selectedItem3.description}
              onChange={({ text }) => {
                const selectedItemNew = { ...selectedItem3 };
                selectedItemNew.description = text;
                setSelectedItem3(selectedItemNew);
              }}
            />

            <div className="flex flex-row justify-center">
              <div className="flex flex-col items-start justify-start">
                <Checkbox
                  name="Mutasd button"
                  selected={selectedItem3.isButton}
                  getValue={(data) => {
                    const selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.isButton = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
                <Checkbox
                  name="Info button"
                  selected={selectedItem3.isInfoButton}
                  getValue={(data) => {
                    const selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.isInfoButton = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
                <Checkbox
                  name="Visible"
                  selected={selectedItem3.visible}
                  getValue={(data) => {
                    const selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.visible = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
              </div>
            </div>
            {newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    setSelectedItem3({});
                    setNew(false);
                    setType('');
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => type === 'third' && createItems()}
                >
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton red type="submit" className="w-1/4" onClick={() => deleteItems()}>
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton type="submit" className="w-1/4" onClick={() => save3()}>
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
    </Layout>
  );
};

export default Todo;
