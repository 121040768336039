import moment from 'moment';

export const validateStartEndDates = (item) => {
  if ((!item.start_date && !!item.end_date) || (!item.end_date && !!item.start_date)) {
    return {
      valid: false,
      error: 'Either both start and end date need to be filled or none of them',
    };
  }

  if (
    (!!item.start_date && !moment(item.start_date).isValid()) ||
    (!!item.end_date && !moment(item.end_date).isValid())
  ) {
    return { valid: false, error: 'Invalid start or end date field' };
  }

  return { valid: true, error: null };
};

export const isBetweenPublishDateRange = (item) => {
  if (!item.start_date && !item.end_date) return true;
  if (!!item.start_date && !item.end_date)
    return moment().isAfter(moment(item.start_date).startOf('day'));
  if (!item.start_date && !!item.end_date)
    return moment().isBefore(moment(item.end_date).endOf('day'));

  return moment().isBetween(
    moment(item.start_date).startOf('day'),
    moment(item.end_date).endOf('day'),
    undefined,
    '[]'
  );
};

export const isStartingInFuture = (item) => {
  return moment(item.start_date).isAfter();
};
