import React from 'react';
import Layout from '../../components/layout/Layout';
import ActiveSubscriptionsReport from '../../components/analytics/subscription/ActiveSubscriptionsReport';

const ActiveSubscriptions = () => {
  return (
    <Layout active="active-subscriptions">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">
          Active Subscriptions
        </div>
      </div>

      <ActiveSubscriptionsReport />
    </Layout>
  );
};

export default ActiveSubscriptions;
