import { combineReducers } from 'redux';

import state from './state';
import auth from './auth';
import api from './api';
import analytics from './analytics';
import forms from './forms';
import tools from './tools';
import settings from './settings';
import notificationCenter from './notificationCenter';
import deals from './deals';
import premiumSettings from './premiumSettings';

const rootReducer = combineReducers({
  state,
  auth,
  api,
  analytics,
  forms,
  tools,
  settings,
  notificationCenter,
  deals,
  premiumSettings,
});

export default rootReducer;
