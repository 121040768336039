/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveSubscriptions } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import DateSelector from '../../DateSelector';
import Spinner from '../../Spinner';
import { CSVLink } from 'react-csv';

const ActiveSubscriptionsReports = () => {
  const styles = {
    td: {
      breakWords: 'true',
      overflowWrap: 'break-word',
      wordBreak: 'break-all',
    },
  };
  const dispatch = useDispatch();
  const allLoading = useSelector((state) => state.analytics.activeSubscriptionsLoading);
  const activeSubscriptions = useSelector((state) => state.analytics.activeSubscriptions);
  const [startDate, setStartDate] = useState(new Date());

  const search = () => {
    dispatch(getActiveSubscriptions(startDate));
  };

  useEffect(() => {
    if (startDate !== '') {
      dispatch(getActiveSubscriptions(startDate));
    }
  }, []);

  const headers = [
    { label: 'ID', key: 'userid' },
    { label: 'Short ID', key: 'shortId' },
    { label: 'Operating system', key: 'operatingSystem' },
    { label: 'Date and time of subscription', key: 'createdAt' },
    { label: 'Type of subscription', key: 'type' },
    { label: 'Cancelled', key: 'cancelled' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        startDateChanged={(date) => setStartDate(date)}
        showEndDate={false}
      />

      <div className="flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>

        {!allLoading && activeSubscriptions.length !== 0 && (
          <CSVLink
            className="bg-orange text-white p-2 rounded"
            filename={`ActiveSubscriptions-${formatDate(startDate)}.csv`}
            data={activeSubscriptions}
            headers={headers}
          >
            Download this table
          </CSVLink>
        )}
      </div>

      <div className="mx-12">
        {allLoading && <Spinner />}
        {!allLoading && activeSubscriptions.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Short ID</th>
                  <th>Operating system</th>
                  <th>Date and time of subscription</th>
                  <th>Type of subscription</th>
                  <th>Cancelled</th>
                </tr>
              </thead>
              <tbody>
                {activeSubscriptions.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={styles.td}>{item.userid}</td>
                      <td style={styles.td}>{item.shortId}</td>
                      <td style={styles.td}>{item.operatingSystem}</td>
                      <td style={styles.td}>{item.createdAt}</td>
                      <td style={styles.td}>{item.type}</td>
                      <td style={styles.td}>{item.cancelled ? 'Yes' : ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveSubscriptionsReports;
