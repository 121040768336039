import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelector = ({
  startDate,
  endDate,
  startDateChanged,
  endDateChanged,
  showEndDate = true,
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="h-10 border-2 rounded border-disabled py-2 px-4 w-36 focus:outline-none focus:border-orange"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  return (
    <div className="flex justify-center w-full my-8">
      <DatePicker
        dateFormat="yyyy.MM.dd"
        selected={startDate}
        maxDate={new Date()}
        showYearDropdown={true}
        showMonthDropdown={true}
        customInput={<ExampleCustomInput />}
        onChange={(date) => {
          date.setHours(0, 0, 0, 0);
          startDateChanged(date);
        }}
      />
      {showEndDate ? (
        <>
          <div style={{ width: 20 }} />
          <DatePicker
            dateFormat="yyyy.MM.dd"
            selected={endDate}
            maxDate={new Date()}
            showYearDropdown={true}
            showMonthDropdown={true}
            customInput={<ExampleCustomInput />}
            onChange={(date) => {
              date.setHours(23, 59, 59, 999);
              endDateChanged(date);
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default DateSelector;
