import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshCache } from '../../../redux/actions/api';
import { logout } from '../../../redux/actions/state';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import './style.css';

const SidebarMenu = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutNow = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div className="fixed top-0 left-0 bottom-0 bg-orange rounded mt-2 ml-2 mb-2 w-64">
      <div className={'relative'}>
        <img alt="logo" src={logo} className="h-20 w-auto mx-auto mt-2" />
        {process.env.REACT_APP_LANGUAGE === 'de' ? (
          <span className={'absolute -top-1 right-2 text-5xl'}>🇩🇪</span>
        ) : null}
      </div>
      <ul className="overflow-scroll" style={{ height: '100%', paddingBottom: '160px' }}>
        <MenuItem
          title="Content"
          active={
            active === 'home' ||
            active === 'deals' ||
            active === 'tools' ||
            active === 'todo' ||
            active === 'knowledge' ||
            active === 'notification' ||
            active === 'notification-center' ||
            active === 'popup' ||
            active === 'premium-settings' ||
            active === 'settings'
          }
          heading
          content
        />
        <ul className="ml-8 my-1px">
          <MenuItem to="/home" title="Home" active={active === 'home'} />
          <MenuItem to="/deals" title="Deals" active={active === 'deals'} />
          <MenuItem to="/tools" title="Tools" active={active === 'tools'} />
          <MenuItem to="/todo" title="Todo" active={active === 'todo'} />
          <MenuItem to="/knowledge" title="Knowledge" active={active === 'knowledge'} />
          <MenuItem
            to="/notification"
            title="Push notifications"
            active={active === 'notification'}
          />
          <MenuItem
            to="/notification-center"
            title="Notification center"
            active={active === 'notification-center'}
          />
          <MenuItem to="/popup" title="Popup" active={active === 'popup'} />
          <MenuItem
            to="/premium-settings"
            title="Premium settings"
            active={active === 'premium-settings'}
          />
          <MenuItem to="/settings" title="Settings" active={active === 'settings'} />
        </ul>
        <MenuItem
          title="Analytics"
          active={
            active === 'useranalytics' ||
            active === 'contentanalytics' ||
            active === 'companyanalytics'
          }
          heading
        />
        <ul className="ml-8 my-1px">
          <MenuItem to="/useranalytics" title="Users" active={active === 'useranalytics'} />
          <MenuItem
            to="/active-subscriptions"
            title="Active subscriptions"
            active={active === 'active-subscriptions'}
          />
          <MenuItem
            to="/premium-analytics"
            title="Premium Analytics"
            active={active === 'premium-analytics'}
          />
          <MenuItem
            to="/contentanalytics"
            title="App content"
            active={active === 'contentanalytics'}
          />
          <MenuItem to="/companyanalytics" title="Company" active={active === 'companyanalytics'} />
        </ul>
        <MenuItem to="/forms" title="Forms" active={active === 'forms'} />
        <MenuItem to="/help" title="Help" active={active === 'help'} />
        <MenuItem to="/appversion" title="AppVersion" active={active === 'appversion'} />
        <MenuItem to="/userdelete" title="Delete user" active={active === 'userdelete'} />
        <MenuItem title="Logout" active={active === 'b'} onClick={() => logoutNow()} logout />
      </ul>

      <UpdateAppData />
    </div>
  );
};

const MenuItem = ({ to, title, active, onClick, heading, logout, content }) => {
  const { role } = useSelector((state) => state?.auth?.user);
  const renderMenuItem = () => {
    if (
      role === 'admin' ||
      (role === 'photographer_editor' && to === '/tools') ||
      (role === 'knowledge_editor' && to === '/knowledge') ||
      (role === 'calendar_editor' && to === '/home') ||
      logout ||
      content
    )
      return true;
    return false;
  };

  return renderMenuItem() ? (
    <li
      className={`relative z-10 flex flex-row h-7 mx-2 my-1px rounded ${
        active && 'border-2 border-white'
      }`}
    >
      {heading && <div className="text-base text-white ml-2 my-auto ">{title}</div>}
      {to && (
        <Link to={to} title={title} className="text-base text-white ml-2 my-auto">
          {title}
        </Link>
      )}
      {logout && (
        <div
          type="button"
          title={title}
          className="text-base text-white ml-2 my-auto focus:underline cursor:pointer"
          onClick={() => onClick()}
        >
          {title}
        </div>
      )}
    </li>
  ) : (
    <></>
  );
};

export const UpdateAppData = () => {
  const dispatch = useDispatch();

  const updateAppWithNewData = () => {
    dispatch(refreshCache());
  };

  return (
    <div className={'update-app-data'}>
      <button
        type={'button'}
        className={'block mx-auto px-2 rounded h-10 bg-extralightorange'}
        onClick={() => updateAppWithNewData()}
      >
        Update app with new data
      </button>
    </div>
  );
};

export default SidebarMenu;
