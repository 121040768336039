import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  getAllData,
  getPopups,
  createPopup,
  updatePopup,
  deletePopup,
  duplicatePopup,
} from '../../redux/actions/api';
import DatePicker from 'react-datepicker';
import Spinner from '../../components/Spinner';
import ImageUp from '../../components/ImageUp';
import { nanoid } from 'nanoid';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import NewButton from '../../components/NewButton';
import CustomButton from '../../components/CustomButton';

// styles
import 'react-datepicker/dist/react-datepicker.css';

const POPUP_ITEM_TYPE = [
  { value: 'heading', label: 'heading' },
  { value: 'text', label: 'text' },
  { value: 'image', label: 'image' },
  { value: 'button', label: 'button' },
];
const SCREENS = [
  { value: 'home', label: 'home' },
  { value: 'baby-mum', label: 'baby-mum' },
  { value: 'knowledge', label: 'knowledge' },
  { value: 'tools', label: 'tools' },
  { value: 'todo', label: 'todo' },
  { value: 'knowledge-category', label: 'knowledge-category' },
  { value: 'knowledge-article', label: 'knowledge-article' },
  { value: 'photographer', label: 'photographer' },
  { value: 'timer', label: 'timer' },
  { value: 'diary', label: 'diary' },
  { value: 'diary-details', label: 'diary-details' },
  { value: 'food-safety', label: 'food-safety' },
  { value: 'knowledge-faq', label: 'knowledge-faq' },
  { value: 'recipe-detail', label: 'recipe-detail' },
  { value: 'recipe-article', label: 'recipe-article' },
  { value: 'weight-curve', label: 'weight-curve' },
  { value: 'deals', label: 'deals' },
];

const SCREENS_WITH_ID = ['baby-mum', 'knowledge-category', 'knowledge-article'];

const initSelectedItem = {
  id: '',
  name: '',
  start_date: '',
  end_date: '',
  from_week: 0,
  to_week: 40,
  open_delay_in_sec: 0,
  impression_url: '',
  screens: [],
  content: [],
  product: '',
  company_name: '',
};

class Popup extends Component {
  constructor() {
    super();
    this.state = {
      type: 'create',
      selectedItem: {
        ...initSelectedItem,
      },
      selectedComponentValue: '',
      selectedPopupComponent: '',
      buttonLabel: '',
      buttonColor: '#61cbbe',
      date: '',
      popups: 0,
      startDate: new Date('2019.01.01'),
      endDate: new Date(),
      popupindex: null,
      componentErrorMessage: '',
      selectedScreen: '',
      selectedScreenId: '',
      showModal: false,
      screenRequiredMessage: '',
    };
  }

  componentDidMount() {
    this.popupData();
  }

  async popupData() {
    await Promise.all([this.props.getPopups(), this.props.getAllData()]);
  }

  createNewPopup() {
    const isThereButtonComponent = this.state.selectedItem.content.some((c) => c.type === 'button');
    const isThereSelectedScreen =
      this.state.selectedItem.screens && this.state.selectedItem.screens.length > 0;
    if (!isThereSelectedScreen)
      return this.setState({ screenRequiredMessage: 'You must add one screen at least' });
    if (!isThereButtonComponent)
      return this.setState({ componentErrorMessage: 'You must add a button component' });
    if (
      !this.state.selectedItem.name ||
      !this.state.selectedItem.start_date ||
      !this.state.selectedItem.end_date
    )
      return alert('Not all required fields are filled');
    if (this.state.selectedItem.from_week < 0 || this.state.selectedItem.from_week > 40)
      return alert('"From week" field should be in 0 - 40 interval');
    if (this.state.selectedItem.to_week < 0 || this.state.selectedItem.to_week > 40)
      return alert('"To week" field should be in 0 - 40 interval');
    if (this.state.type === 'create') {
      this.props.createPopup(this.state.selectedItem);
    }
    if (this.state.type === 'update') {
      const id = this.state.selectedItem.id;
      this.props.updatePopup(id, this.state.selectedItem);
    }
    this.setState({
      type: 'create',
      popupindex: null,
      selectedItem: structuredClone(initSelectedItem),
      selectedComponentValue: '',
      selectedPopupComponent: '',
      buttonLabel: '',
      buttonColor: '#61cbbe',
      componentErrorMessage: '',
      selectedScreen: '',
      selectedScreenId: '',
      screenRequiredMessage: '',
    });
  }

  async duplicatePopup(id, defaultStartDate, defaultEndDate) {
    const name = window.prompt('Please enter the name of the new popup');
    if (!name) return;

    const startDate = window.prompt(
      'Please enter the start date of the new popup (YYYY-MM-DD format)',
      defaultStartDate
    );
    if (!startDate) return;

    const endDate = window.prompt(
      'Please enter the end date of the new popup (YYYY-MM-DD format)',
      defaultEndDate
    );
    if (!endDate) return;

    await this.props.duplicatePopup(id, {
      name,
      start_date: startDate,
      end_date: endDate,
    });
    this.choosePopup(0);
  }

  deletePopup(id) {
    const isConfirmed = window.confirm('Are you sure sure you want to delete this popup?');

    if (!isConfirmed) return;

    this.props.deletePopup(id);
    this.setState({
      type: 'create',
      popupindex: null,
      selectedItem: structuredClone(initSelectedItem),
      selectedComponentValue: '',
      selectedPopupComponent: '',
      buttonLabel: '',
      buttonColor: '#61cbbe',
      componentErrorMessage: '',
      selectedScreen: '',
      selectedScreenId: '',
      screenRequiredMessage: '',
    });
  }

  choosePopup(index) {
    const popups = this.props.popups;
    const selectedPopup = popups[index];

    this.setState({
      type: 'update',
      selectedItem: {
        ...selectedPopup,
        start_date: format(new Date(selectedPopup.start_date), 'yyyy-MM-dd'),
        end_date: format(new Date(selectedPopup.end_date), 'yyyy-MM-dd'),
      },
      popupindex: index,
      componentErrorMessage: '',
      screenRequiredMessage: '',
    });

    window.scrollTo(0, 0, { behavior: 'smooth' });
  }

  renderDateSelector(data) {
    return (
      <div className="flex flex-col gap-3">
        <h1 className="text-center mt-7 font-bold text-xl">Dátumkezelő</h1>
        <div className="flex gap-4 justify-center">
          <DatePicker
            className="border-2 border-extralightorange border-solid rounded p-2 focus:border-2 focus:border-orange focus:outline-none bg-white"
            dateFormat="yyyy.MM.dd"
            selected={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />
          <DatePicker
            className="border-2 border-extralightorange border-solid rounded p-2 focus:border-2 focus:border-orange focus:outline-none bg-white"
            dateFormat="yyyy.MM.dd"
            selected={this.state.endDate}
            onChange={(date) => this.setState({ endDate: date })}
          />
        </div>
        {data === 'popups' && this.props.loading && <Spinner />}
      </div>
    );
  }

  renderCreatePopup() {
    let popupOptions = POPUP_ITEM_TYPE;

    if (this.state.selectedItem.content.some(({ type }) => type === 'button')) {
      popupOptions = popupOptions.filter((option) => option.label !== 'button');
    }

    return (
      <div className="flex items-center mt-8">
        <form className="Form2" style={{ width: '100%' }}>
          <TextField
            type="text"
            label="Internal name*"
            name="name"
            placeholder="Internal name"
            required
            value={this.state.selectedItem.name}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.name = event.target.value;
              this.setState({ selectedItem });
            }}
          />

          <TextField
            type="text"
            label="Start Date*"
            name="start_date"
            placeholder="2023-01-01"
            required
            value={this.state.selectedItem.start_date}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.start_date = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField
            type="text"
            label="End Date*"
            name="end_date"
            placeholder="2023-01-02"
            required
            value={this.state.selectedItem.end_date}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.end_date = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField
            type="number"
            min={0}
            max={40}
            label="From week*"
            name="from_week"
            required
            value={this.state.selectedItem.from_week}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.from_week = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField
            type="number"
            min={0}
            max={40}
            label="To week*"
            name="to_week"
            required
            value={this.state.selectedItem.to_week}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.to_week = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField
            type="text"
            label="Open delay in sec"
            name="open_delay_in_sec"
            required
            value={this.state.selectedItem.open_delay_in_sec}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.open_delay_in_sec = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField
            type="text"
            label="Impression URL (should start with https://)"
            name="impression_url"
            required
            value={this.state.selectedItem.impression_url}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.impression_url = event.target.value;
              this.setState({ selectedItem });
            }}
          />
          <TextField label="Company name">
            <Dropdown
              value={this.state.selectedItem.company_name}
              options={[{ value: '', label: 'None' }, ...this.props.companyList]}
              onChange={(value) => {
                const selectedItem = { ...this.state.selectedItem };
                selectedItem.company_name = value.label;
                this.setState({ selectedItem });
              }}
              className={'w-2/3'}
            />
          </TextField>
          <TextField
            type="text"
            label="Product"
            name="product"
            required
            value={this.state.selectedItem.product}
            onChange={(event) => {
              const selectedItem = { ...this.state.selectedItem };
              selectedItem.product = event.target.value;
              this.setState({ selectedItem });
            }}
          />

          <div>
            <h3 className="font-semibold text-lg">Screens</h3>

            <div className="flex items-center gap-8 justify-end">
              <div className="w-[350px]">
                <Dropdown
                  placeholder="Select a screen..."
                  options={SCREENS.filter(
                    (screen) =>
                      !this.state.selectedItem.screens
                        .map((screen) => screen.name)
                        .includes(screen.value)
                  )}
                  value={this.state.selectedScreen}
                  onChange={(event) => {
                    this.setState({ selectedScreen: event.value });
                  }}
                />
              </div>
              <TextField
                rootClassName="w-auto"
                groupClassName="flex-1 w-auto gap-3"
                labelClassName="w-auto"
                fieldClassName="w-auto"
                type="text"
                label="Id (babyMumButtonIndex, categoryId or articleId)"
                required
                disabled={!SCREENS_WITH_ID.includes(this.state.selectedScreen)}
                value={this.state.selectedScreenId}
                onChange={(event) => {
                  this.setState({ selectedScreenId: event.target.value });
                }}
              />
              <CustomButton onClick={() => this.handleAddScreen()}>Add</CustomButton>
            </div>
            <div className="flex items-end flex-col">
              {this.state.selectedItem.screens?.length === 0 ? (
                <p className="text-red">
                  <i>
                    <b>You have to select at least one screen.</b>
                  </i>
                </p>
              ) : (
                this.state.selectedItem.screens.map((screen, index) => (
                  <div key={`${screen.screenId}-${index}`} className="flex items-center gap-20">
                    <div className="flex gap-7">
                      <p>
                        <i>Screen</i>: <b>{screen.name}</b>
                      </p>

                      {screen.screenId && (
                        <p>
                          <i>ID</i>: <b>{screen.screenId}</b>
                        </p>
                      )}
                    </div>
                    <CustomButton red onClick={() => this.handleDelete(screen.id)}>
                      Delete
                    </CustomButton>
                  </div>
                ))
              )}
            </div>
          </div>

          <h3 className="font-semibold text-lg mb-3">Contents</h3>
          <p className="text-sm italic">Please select a component and add to the popup content</p>
          <p style={{ color: 'red' }}>{this.state.componentErrorMessage}</p>
          <Dropdown
            value={this.state.selectedPopupComponent}
            disabled={this.state.disableButtonOption}
            options={popupOptions}
            onChange={(event) => {
              this.setState({
                selectedComponentValue: '',
                buttonLabel: '',
                buttonColor: undefined,
                selectedPopupComponent: event.value,
              });
            }}
          />
          {['heading', 'text'].includes(this.state.selectedPopupComponent) &&
            this.renderHeadingAndTextComponent(
              this.state.selectedPopupComponent,
              this.state.selectedComponentValue
            )}
          {this.state.selectedPopupComponent === 'image' && this.renderImageComponent()}
          {this.state.selectedPopupComponent === 'button' &&
            this.renderButtonComponent(
              this.state.buttonLabel,
              this.state.selectedComponentValue,
              this.state.buttonColor
            )}
          {this.state.selectedPopupComponent !== '' && (
            <CustomButton className="flex ml-auto" onClick={() => this.addComponent()}>
              Add component
            </CustomButton>
          )}

          <div className="my-5 bg-orange w-full h-2 rounded-md"></div>
          <div>
            <h3 className="text-lg font-semibold">Popup Content</h3>
            {this.state.selectedItem.content.map((c) => {
              if (['heading', 'text'].includes(c.type))
                return this.renderHeadingAndTextComponent(c.type, c.value, c.id);
              if (c.type === 'image') return this.renderImageComponent(c.id, c.value);
              if (c.type === 'button')
                return this.renderButtonComponent(c.value.label, c.value.url, c.value.color, c.id);
              return <div></div>;
            })}
          </div>

          <div className="flex justify-center mt-10">
            <CustomButton onClick={() => this.createNewPopup()}>Send</CustomButton>
            {this.state.type === 'update' && (
              <CustomButton
                onClick={() =>
                  this.duplicatePopup(
                    this.state.selectedItem.id,
                    this.state.selectedItem.start_date,
                    this.state.selectedItem.end_date
                  )
                }
              >
                Duplicate
              </CustomButton>
            )}
            {this.state.type === 'update' && (
              <CustomButton red onClick={() => this.deletePopup(this.state.selectedItem.id)}>
                Delete
              </CustomButton>
            )}
          </div>
        </form>
      </div>
    );
  }

  renderPopups() {
    const popups = this.props.popups;

    // Sort by start date from most recent to least, if start date equals, sort alphabetically
    popups.sort((a, b) => {
      const dateResult = new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
      if (dateResult === 0) return a.name.localeCompare(b.name);
      return dateResult;
    });

    return (
      <div className="flex gap-1 flex-wrap">
        <NewButton
          onClick={() =>
            this.setState({
              type: 'create',
              selectedItem: structuredClone(initSelectedItem),
              componentErrorMessage: '',
              selectedScreen: '',
              selectedScreenId: '',
              screenRequiredMessage: '',
              popupindex: null,
            })
          }
          title="Add Popup"
        />
        {popups.map((popup, index) => (
          <MenuButton
            key={popup.id}
            onClick={() => this.choosePopup(index)}
            active={this.state.popupindex === index}
            title={popup.name}
            visible="true"
          />
        ))}
      </div>
    );
  }

  renderHeadingAndTextComponent(type, value = '', id = '') {
    return (
      <div className="flex">
        <TextArea
          label={type === 'heading' ? 'Heading text*' : 'Normal text*'}
          name="heading"
          required
          value={value}
          onChange={(event) => {
            if (id === '') {
              this.setState({ selectedComponentValue: event.target.value });
            } else {
              const selectedItem = { ...this.state.selectedItem };
              const component = selectedItem.content.find((c) => c.id === id);
              component.value = event.target.value;
              this.setState({ selectedItem });
            }
          }}
        />
        {id && (
          <CustomButton red onClick={() => this.deletePopupComponent(id)}>
            Delete
          </CustomButton>
        )}
      </div>
    );
  }

  renderImageComponent(id = '', value = '') {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label
            style={{
              paddingBottom: 0,
            }}
          >
            Image*
          </label>
          {id && (
            <CustomButton red onClick={() => this.deletePopupComponent(id)}>
              Delete
            </CustomButton>
          )}
        </div>
        {id === '' ? (
          <ImageUp
            value={this.state.selectedComponentValue}
            parentCallback={() => {
              this.setState({ selectedComponentValue: '' });
            }}
            getImg={(v) => {
              this.setState({ selectedComponentValue: v });
            }}
          />
        ) : (
          <ImageUp
            value={value}
            parentCallback={() => {
              const selectedItem = { ...this.state.selectedItem };
              const component = selectedItem.content.find((c) => c.id === id);
              component.value = '';
              this.setState({ selectedItem });
            }}
            getImg={(v) => {
              const selectedItem = { ...this.state.selectedItem };
              const component = selectedItem.content.find((c) => c.id === id);
              component.value = v;
              this.setState({ selectedItem });
            }}
          />
        )}
      </div>
    );
  }

  renderButtonComponent(label = '', url = '', color = '#61cbbe', id = '') {
    return (
      <div>
        {id === '' ? (
          <div>
            <TextField
              type="text"
              label="Button label*"
              name="buttonlabel"
              required
              value={label}
              onChange={(event) => {
                this.setState({ buttonLabel: event.target.value });
              }}
            />
            <TextArea
              label="Button url*"
              name="buttonurl"
              required
              value={url}
              onChange={(event) => {
                this.setState({ selectedComponentValue: event.target.value });
              }}
            />
            <TextField
              type="text"
              label="Button color"
              name="buttoncolor"
              required
              value={color}
              onChange={(event) => {
                this.setState({ buttonColor: event.target.value });
              }}
            />
          </div>
        ) : (
          <div className="flex" key={id}>
            <div className="w-full">
              <TextField
                type="text"
                label="Button label*"
                name="buttonlabel"
                required
                value={label}
                onChange={(event) => {
                  const selectedItem = { ...this.state.selectedItem };
                  const component = selectedItem.content.find((c) => c.id === id);
                  component.value.label = event.target.value;
                  this.setState({ selectedItem });
                }}
              />
              <TextArea
                label="Button url*"
                name="buttonurl"
                required
                value={url}
                onChange={(event) => {
                  const selectedItem = { ...this.state.selectedItem };
                  const component = selectedItem.content.find((c) => c.id === id);
                  component.value.url = event.target.value;
                  this.setState({ selectedItem });
                }}
              />
              <TextField
                type="text"
                label="Button color"
                name="buttoncolor"
                required
                value={color}
                onChange={(event) => {
                  const selectedItem = { ...this.state.selectedItem };
                  const component = selectedItem.content.find((c) => c.id === id);
                  component.value.color = event.target.value;
                  this.setState({ selectedItem });
                }}
              />
            </div>
            <CustomButton red onClick={() => this.deletePopupComponent(id)}>
              Delete
            </CustomButton>
          </div>
        )}
      </div>
    );
  }

  addComponent() {
    this.setState({ componentErrorMessage: '' });
    if (
      !this.state.selectedComponentValue ||
      (this.state.selectedPopupComponent === 'button' && !this.state.buttonLabel)
    )
      return this.setState({ componentErrorMessage: 'You must fill all component input' });

    const selectedItem = { ...this.state.selectedItem };
    const textComponentIndex = selectedItem.content.findIndex((comp) => comp.type === 'text');

    if (this.state.selectedPopupComponent === 'button') {
      selectedItem.content.push({
        type: this.state.selectedPopupComponent,
        value: { url: this.state.selectedComponentValue, label: this.state.buttonLabel },
        id: nanoid(),
      });
    } else {
      const newComponent = {
        type: this.state.selectedPopupComponent,
        value: this.state.selectedComponentValue,
        id: nanoid(),
      };

      if (this.state.selectedPopupComponent === 'heading' && textComponentIndex > -1) {
        selectedItem.content = [
          ...selectedItem.content.slice(0, textComponentIndex),
          newComponent,
          ...selectedItem.content.slice(textComponentIndex),
        ];
      } else {
        selectedItem.content.push(newComponent);
      }
    }
    this.setState({
      selectedItem,
      selectedPopupComponent: '',
      selectedComponentValue: '',
      buttonLabel: '',
    });
  }

  deletePopupComponent(id) {
    const content = [...this.state.selectedItem.content];
    const newContent = content.filter((c) => c.id !== id);
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        content: [...newContent],
      },
    });
  }

  handleOpen() {
    this.setState({
      showModal: true,
      modalErrorMessage: '',
      selectedScreen: '',
      selectedScreenId: '',
    });
  }

  handleDelete(id) {
    const screens = [...this.state.selectedItem.screens];
    const newScreen = screens.filter((s) => s.id !== id);
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        screens: [...newScreen],
      },
    });
  }

  handleClose() {
    this.setState({ showModal: false, modalErrorMessage: '' });
  }

  handleAddScreen() {
    if (this.state.selectedScreen)
      return this.setState({
        modalErrorMessage: '',
        showModal: false,
        screenRequiredMessage: '',
        selectedScreen: '',
        selectedScreenId: '',
        selectedItem: {
          ...this.state.selectedItem,
          screens: [
            ...this.state.selectedItem.screens,
            {
              name: this.state.selectedScreen,
              screenId: this.state.selectedScreenId,
              id: nanoid(),
            },
          ],
        },
      });
    else return this.setState({ modalErrorMessage: 'Please select a screen.' });
  }

  render() {
    return (
      <Layout active="popup">
        {!this.props.popups || this.props.popups.length === 0 ? (
          <Spinner />
        ) : (
          <div className="w-fit m-12">
            {this.renderPopups()}
            {this.renderCreatePopup()}
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.state,
  popups: state.api.popups,
  user: state.api.user,
  companyList: state.analytics.companylist,
});

const mapDispatchToProps = {
  getPopups,
  createPopup,
  updatePopup,
  duplicatePopup,
  deletePopup,
  getAllData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
