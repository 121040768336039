export const initialState = {
  configs: {},
  error: null,
};

const premiumSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PREMIUM_SETTINGS':
      return {
        ...state,
        configs: action.data,
        error: null,
      };
    case 'GET_PREMIUM_SETTINGS_ERROR':
      return {
        ...state,
        error: 'Failed to fetch premium settings',
      };
    case 'UPDATE_PREMIUM_SETTING':
      return {
        ...state,
        error: null,
      };
    case 'UPDATE_PREMIUM_SETTING_ERROR':
      return {
        ...state,
        error: 'Failed to update premium setting',
      };
    default:
      return state;
  }
};

export default premiumSettings;
