import moment from 'moment';
import { formatDate } from '../../components/analytics/helper';
import { api } from './api';
import { unauthorizedError } from './state';

const TODO_SCREENS = ['mutasd', 'mutasd detail', 'todo-detail', 'todo-item-detail'];

// USERS
export const getUserAll =
  ({ before, after }) =>
  async (dispatch) => {
    dispatch({ type: 'GET_USER_ALL_LOADING' });
    dispatch({ type: 'GET_PG_DATA_SHARING_ACCEPTED_USERS', data: 'Loading...' });

    try {
      const { data: analyticData } = await api.get(`analyticsusers`, {
        params: {
          before: formatDate(before),
          after: formatDate(after),
          limit: Number.MAX_SAFE_INTEGER,
        },
      });

      const pgDataSharingAcceptedUsers = (
        await api.get('analyticsusers/pg-data-sharing', {
          params: {
            from: moment(after).format('YYYY-MM-DD'),
            to: moment(before).format('YYYY-MM-DD'),
          },
          timeout: 1000000,
        })
      ).data.count;
      dispatch({ type: 'GET_USER_ALL', data: analyticData });
      dispatch({ type: 'GET_PG_DATA_SHARING_ACCEPTED_USERS', data: pgDataSharingAcceptedUsers });
    } catch (error) {
      dispatch({ type: 'ERROR', err: error });

      alert(error);

      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    }
  };

export const getUserWeeks = () => async (dispatch) => {
  dispatch({ type: 'GET_USER_WEEKS_LOADING' });

  try {
    const { data: analyticData } = await api.get(`analyticsuserweeks`);
    dispatch({ type: 'GET_USER_WEEKS', data: analyticData });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });

    alert(error);

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getPregnancyWeekHistory = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_PREGNANCY_WEEK_HISTORY_LOADING' });

  try {
    const { data } = await api.get('admin/pregnancy-week-history', {
      params: { from, to },
    });
    dispatch({ type: 'GET_PREGNANCY_WEEK_HISTORY', data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });

    alert(error);

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getUserActivity = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_USER_ACTIVITY_LOADING' });

  try {
    const { data } = await api.get('analyticsactives', {
      params: { from, to },
    });
    dispatch({ type: 'GET_USER_ACTIVITY', data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });

    alert(error);

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// SUBSCRIPTIONS
export const getActiveSubscriptions = (date) => async (dispatch) => {
  dispatch({ type: 'GET_ACTIVE_SUBSCRIPTIONS_LOADING' });

  try {
    const { data } = await api.get(`admin/premium/active-subscriptions`, {
      params: { date: formatDate(date) },
    });
    dispatch({ type: 'GET_ACTIVE_SUBSCRIPTIONS', data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });

    alert(error);

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// CONTENT / SCREENS
export const setLoadingFalse = () => (dispatch) => {
  dispatch({ type: 'SET_LOADING_FALSE' });
};

export const getScreenMain = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_MAIN_LOADING' });

  try {
    const response = await api.get(`analyticsscreens/aggregate`, {
      params: { from, to },
    });

    dispatch({ type: 'GET_SCREEN_MAIN', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenHome = (from, to, screen, groupByWeek) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_HOME_LOADING' });

  try {
    const response = await api.get('analyticsscreens/aggregate/home', {
      params: { from, to, screen, groupByWeek },
    });

    dispatch({ type: 'GET_SCREEN_HOME', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenTools = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_TOOLS_LOADING' });

  try {
    const response = await api.get(`/analyticsscreens/aggregate/tools`, {
      params: { from, to },
    });

    dispatch({ type: 'GET_SCREEN_TOOLS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenPhotographers = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_PHOTOGRAPHERS_LOADING' });

  try {
    const response = await api.get('analyticsscreens/aggregate/photographers', {
      params: { from, to },
    });

    dispatch({ type: 'GET_SCREEN_PHOTOGRAPHERS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenDeals = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_DEALS_LOADING' });

  try {
    const response = await api.get('analyticsscreens/aggregate/deals', {
      params: { from, to },
    });

    dispatch({ type: 'GET_SCREEN_DEALS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenTodo = (from, to, categoryId) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_TODO_LOADING' });

  try {
    const response = await api.get('analyticsscreenstodo/aggregate', {
      params: { from, to, categoryId },
    });

    dispatch({ type: 'GET_SCREEN_TODO', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenTodoItem = (from, to, categoryId) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_TODO_ITEM_LOADING' });

  try {
    const response = await api.get('analyticsscreenstodo/aggregate', {
      params: { from, to, categoryId },
    });

    dispatch({ type: 'GET_SCREEN_TODO_ITEM', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenKnowledge = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_KNOWLEDGE_LOADING' });

  try {
    const response = await api.get('analyticsscreensknowledge/aggregate', {
      params: { from, to },
    });

    const knowledgeDetailScreens = await api.get('analyticsscreens/aggregate/knowledge', {
      params: { from, to },
    });

    const mergedScreens = Object.assign({}, response.data);
    if (knowledgeDetailScreens.data.totalAppeared !== null)
      mergedScreens.totalAppeared += knowledgeDetailScreens.data.totalAppeared;
    if (knowledgeDetailScreens.data.uniq !== null)
      mergedScreens.uniq += knowledgeDetailScreens.data.uniq;

    knowledgeDetailScreens.data.screens.forEach((knowledgeScreen) => {
      const existingScreen = mergedScreens.screens.find(
        (screen) => screen.screen === knowledgeScreen.screen
      );
      if (existingScreen) {
        existingScreen.appeared += knowledgeScreen.appeared;
        existingScreen.unique += knowledgeScreen.unique;
      } else {
        mergedScreens.screens.push(knowledgeScreen);
      }
    });

    mergedScreens.screens.sort((a, z) => a.screen.localeCompare(z.screen));

    dispatch({ type: 'GET_SCREEN_KNOWLEDGE', data: mergedScreens });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getScreenArticle = (from, to, categoryTitle) => async (dispatch) => {
  dispatch({ type: 'GET_SCREEN_ARTICLE_LOADING' });

  try {
    const response = await api.get('analyticsarticles/aggregate', {
      params: { from, to, categoryTitle },
    });

    dispatch({ type: 'GET_SCREEN_ARTICLE', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// CONTENT / CLICKS
export const setClickLoadingFalse = () => (dispatch) => {
  dispatch({ type: 'SET_CLICK_LOADING_FALSE' });
};

export const getClickCoupons = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_CLICK_COUPON_LOADING' });

  try {
    const response = await api.get('analyticsdeals/aggregate', {
      params: { from, to },
    });

    dispatch({ type: 'GET_CLICK_COUPON', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getClickTodos = (from, to, categoryTitle) => async (dispatch) => {
  dispatch({ type: 'GET_CLICK_TODO_LOADING' });

  try {
    const response = await api.get('analyticsdeals/aggregate', {
      params: {
        from,
        to,
        screen: TODO_SCREENS,
        categoryTitle,
        groupByUrl: false,
      },
    });

    dispatch({ type: 'GET_CLICK_TODO', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getClickHome = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_CLICK_HOME_LOADING' });

  try {
    const response = await api.get('analyticslinkknow/aggregate/home', {
      params: { from, to },
    });

    dispatch({ type: 'GET_CLICK_HOME', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getClickKnowledge = (from, to, categoryId) => async (dispatch) => {
  dispatch({ type: 'GET_CLICK_KNOWLEDGE_LOADING' });

  const params = { from, to };
  if (categoryId) {
    params.categoryId = categoryId;
  }

  try {
    const response = await api.get('analyticslinkknow/aggregate', { params });

    dispatch({ type: 'GET_CLICK_KNOWLEDGE', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const getClickRecipe = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_CLICK_RECIPE_LOADING' });

  try {
    const response = await api.get('analyticslinkknow/aggregate/recipe', {
      params: { from, to },
    });

    dispatch({ type: 'GET_CLICK_RECIPE', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// CONTENT / POPUP
export const getPopupAnalytics = (from, to, shown) => async (dispatch) => {
  dispatch({ type: 'GET_POPUP_ANALYTICS_LOADING' });

  try {
    const response = await api.get('analyticspopups/aggregate', {
      params: { from, to, shown: shown ? shown : undefined },
    });

    dispatch({ type: 'GET_POPUP_ANALYTICS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// CONTENT / NOTIFICATION CENTER
export const getNotificationCenterAnalytics = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_NOTIFICATION_CENTER_ANALYTICS_LOADING' });

  try {
    const response = await api.get('/analyticsinappnotifications/aggregate', {
      params: { from, to },
    });

    dispatch({ type: 'GET_NOTIFICATION_CENTER_ANALYTICS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// COMPANY
export const getCompanyAnalytics = (from, to, companyName, product) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_COMPANY_ANALYTICS_LOADING' });

    const companyAndProductFilter = (item) =>
      (!companyName || item.companyname?.includes(companyName)) &&
      (!product || item.product?.includes(product));

    const getItemsForCompany = (items) => {
      const companyItems = items.filter(companyAndProductFilter);

      const urls = [
        ...new Set(companyItems.map((item) => item.link || item.deal).filter((val) => val)),
      ];
      const titles = [...new Set(companyItems.map((item) => item.title).filter((val) => val))];

      let relatedItems = [];
      if (urls.length > 0 || titles.length > 0) {
        relatedItems = items
          .filter((item) => item.companyname.length === 0 || item.company === null)
          .filter(
            (item) =>
              (item.title &&
                titles.some((title) => title === item.title.replaceAll(/ \(banner\)$/g, ''))) ||
              (item.link && urls.includes(item.link)) ||
              (item.deal && urls.includes(item.deal))
          );
      }

      return [...companyItems, ...relatedItems];
    };

    const result = {
      coupons: [],
      couponViews: 0,
      todo: [],
      todoViews: [],
      popups: [],
      notifications: [],
      home: [],
      homeViews: [],
      knowledge: [],
      knowledgeViews: [],
    };

    // COUPONS
    try {
      const coupons = await api.get('analyticsdeals/aggregate', {
        params: { from, to, groupByUrl: false },
      });
      result.coupons = getItemsForCompany(coupons.data.screens);

      const couponViews = await api.get('analyticsscreens/aggregate', {
        params: { from, to, screen: ['deals'] },
      });
      result.couponViews = couponViews.data.screens.reduce((sum, screen) => {
        sum += screen.appeared;
        return sum;
      }, 0);

      // TODOS
      const todos = await api.get('analyticsdeals/aggregate', {
        params: {
          from,
          to,
          screen: TODO_SCREENS,
        },
      });
      result.todo = getItemsForCompany(todos.data.screens);

      const todoViews = await api.get('analyticsscreenstodo/aggregate', {
        params: { from, to, groupByParent: false },
      });
      result.todoViews = todoViews.data.screens;

      // POPUPS
      const popups = await api.get('analyticspopups/aggregate', {
        params: { from, to },
      });
      result.popups = getItemsForCompany(popups.data.screens);

      // IN-APP NOTIFICATIONS
      const notifications = await api.get('analyticsinappnotifications/aggregate', {
        params: { from, to, companyName, product },
      });
      result.notifications = notifications.data.notifications;

      // HOME
      const home = await api.get('analyticslinkknow/aggregate/home', {
        params: { from, to, companyName, product },
      });
      result.home = home.data.screens;

      const homeViews = await api.get('analyticsscreens/aggregate/home', {
        params: { from, to, groupByWeek: true },
      });
      result.homeViews = homeViews.data.screens;

      // KNOWLEDGE
      const knowledge = await api.get('analyticslinkknow/aggregate', {
        params: { from, to },
      });
      result.knowledge = getItemsForCompany(knowledge.data.screens);

      const knowledgeArticleScreens = await api.get('analyticsarticles/aggregate', {
        params: { from, to },
      });
      result.knowledgeViews = knowledgeArticleScreens.data.articles;
    } catch (error) {
      dispatch({ type: 'ERROR', err: error });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    }

    dispatch({
      type: 'GET_COMPANY_ANALYTICS',
      data: result,
    });
  };
};

export const getCompanyList = () => async (dispatch) => {
  dispatch({ type: 'GET_COMPANY_LIST_LOADING' });

  try {
    const response = await api.get('/company/list', { params: { limit: 200 } });

    const data = response.data.map((item) => {
      return { id: item.id, value: item.company, label: item.company };
    });

    dispatch({ type: 'GET_COMPANY_LIST', data });

    return data;
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const createNewCompany = (company) => async (dispatch) => {
  dispatch({ type: 'CREATE_COMPANY_LIST_LOADING' });

  try {
    await api.post('/company', {
      company,
    });

    alert('Created new company');
    dispatch(getCompanyList());
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

export const setCompanyLoadedFalse = () => (dispatch) => {
  dispatch({ type: 'COMPANY_LOADED_FALSE' });
};

export const getUserTodos = (from, to, subcategory) => async (dispatch) => {
  dispatch({ type: 'GET_USER_TODOS_LOADING' });

  try {
    const response = await api.get('analyticsusertodos', {
      params: { from, to, subcategory },
    });
    const data = response.data;

    dispatch({ type: 'GET_USER_TODOS', data });
    return data;
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};

// PREMIUM TRANSACTIONS
export const getPremiumTransactions = (from, to) => async (dispatch) => {
  dispatch({ type: 'GET_PREMIUM_TRANSACTIONS_LOADING' });

  try {
    const response = await api.get('/admin/premium/transactions', {
      params: { from, to },
    });

    dispatch({ type: 'GET_PREMIUM_TRANSACTIONS', data: response.data });
  } catch (error) {
    dispatch({ type: 'ERROR', err: error });
    alert(error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(unauthorizedError());
      }
    }
  }
};
