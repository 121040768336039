export const initialState = {
  loading: false,
  error: '',
  // user
  userall: [],
  userall_loading: false,
  userweeks: [],
  userweeks_loading: false,
  useractivity: [],
  useractivity_loading: false,
  pregnancyWeekHistory: [],
  pregnancyWeekHistory_loading: false,

  // subscriptions
  activeSubscriptions: [],
  activeSubscriptionsLoading: false,

  // content screen
  screenmain: [],
  screenmain_loading: false,
  screenknowledge: [],
  screenknowledge_loading: false,
  screenarticle: [],
  screenarticle_loading: false,
  screenhome: [],
  screenhome_loading: false,
  screentodo: [],
  screentodo_loading: false,
  screentodoitem: [],
  screentodoitem_loading: false,
  screentool: [],
  screentool_loading: false,
  screenphotographers: [],
  screenphotographers_loading: false,
  screendeals: [],
  screendeals_loading: false,

  // content click
  clickcoupon: [],
  clickcoupon_loading: false,
  clicktodo: [],
  clicktodo_loading: false,
  clickhome: [],
  clickhome_loading: false,
  clickknowledge: [],
  clickknowledge_loading: false,
  clickarticle: [],
  clickarticle_loading: false,
  clickrecipe: [],
  clickrecipe_loading: false,

  popup_analytics: [],
  popup_date_analytics: [],
  popup_analytics_loading: false,

  notification_center_analytics: [],
  notification_center_analytics_loading: false,

  company: {},
  company_loading: false,
  company_loaded: false,
  companylist: [],

  user_todos: [],
  user_todos_loading: false,

  premium_transactions: [],
  premium_transactions_loading: false,
};

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_TRUE':
      return {
        ...state,
        loading: true,
      };
    case 'SET_LOADING_FALSE':
      return {
        ...state,
        loading: false,
        screenmain_loading: false,
        screenhome_loading: false,
        screentodo_loading: false,
        screentodoitem_loading: false,
        screenknowledge_loading: false,
        screenarticle_loading: false,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        screenmain_loading: false,
        screenhome_loading: false,
        screentodo_loading: false,
        screentodoitem_loading: false,
        screenknowledge_loading: false,
        screenarticle_loading: false,
        error: action.err,
      };

    // USERS
    case 'GET_USER_ALL':
      return {
        ...state,
        userall: action.data,
        userall_loading: false,
      };
    case 'GET_USER_ALL_LOADING':
      return {
        ...state,
        userall_loading: true,
      };
    case 'GET_PG_DATA_SHARING_ACCEPTED_USERS':
      return {
        ...state,
        pgDataSharingAcceptedUsers: action.data,
      };
    case 'GET_USER_WEEKS':
      return {
        ...state,
        userweeks: action.data,
        userweeks_loading: false,
      };
    case 'GET_USER_WEEKS_LOADING':
      return {
        ...state,
        userweeks: action.data,
        userweeks_loading: true,
      };
    case 'GET_PREGNANCY_WEEK_HISTORY':
      return {
        ...state,
        pregnancyWeekHistory: action.data,
        pregnancyWeekHistory_loading: false,
      };
    case 'GET_PREGNANCY_WEEK_HISTORY_LOADING':
      return {
        ...state,
        pregnancyWeekHistory: action.data,
        pregnancyWeekHistory_loading: true,
      };
    case 'GET_USER_ACTIVITY':
      return {
        ...state,
        useractivity: action.data,
        useractivity_loading: false,
      };
    case 'GET_USER_ACTIVITY_LOADING':
      return {
        ...state,
        useractivity_loading: true,
      };

    // SUBSCRIPTIONS
    case 'GET_ACTIVE_SUBSCRIPTIONS':
      return {
        ...state,
        activeSubscriptions: action.data,
        activeSubscriptionsLoading: false,
      };

    case 'GET_ACTIVE_SUBSCRIPTIONS_LOADING':
      return {
        ...state,
        activeSubscriptionsLoading: true,
      };

    // CONTENT / SCREENS
    case 'GET_SCREEN_MAIN_LOADING':
      return {
        ...state,
        screenmain_loading: true,
      };
    case 'GET_SCREEN_MAIN':
      return {
        ...state,
        screenmain: action.data,
        screenmain_loading: false,
      };
    case 'GET_SCREEN_HOME_LOADING':
      return {
        ...state,
        screenhome_loading: true,
      };
    case 'GET_SCREEN_HOME':
      return {
        ...state,
        screenhome: action.data,
        screenhome_loading: false,
      };
    case 'GET_SCREEN_TOOLS_LOADING':
      return {
        ...state,
        screentool_loading: true,
      };
    case 'GET_SCREEN_TOOLS':
      return {
        ...state,
        screentool: action.data,
        screentool_loading: false,
      };
    case 'GET_SCREEN_PHOTOGRAPHERS_LOADING':
      return {
        ...state,
        screenphotographers_loading: true,
      };
    case 'GET_SCREEN_PHOTOGRAPHERS':
      return {
        ...state,
        screenphotographers: action.data,
        screenphotographers_loading: false,
      };
    case 'GET_SCREEN_DEALS_LOADING':
      return {
        ...state,
        screendeals_loading: true,
      };
    case 'GET_SCREEN_DEALS':
      return {
        ...state,
        screendeals: action.data,
        screendeals_loading: false,
      };
    case 'GET_SCREEN_TODO_LOADING':
      return {
        ...state,
        screentodo_loading: true,
      };
    case 'GET_SCREEN_TODO':
      return {
        ...state,
        screentodo: action.data,
        screentodo_loading: false,
      };
    case 'GET_SCREEN_TODO_ITEM_LOADING':
      return {
        ...state,
        screentodoitem_loading: true,
      };
    case 'GET_SCREEN_TODO_ITEM':
      return {
        ...state,
        screentodoitem: action.data,
        screentodoitem_loading: false,
      };
    case 'GET_SCREEN_KNOWLEDGE_LOADING':
      return {
        ...state,
        screenknowledge_loading: true,
      };
    case 'GET_SCREEN_KNOWLEDGE':
      return {
        ...state,
        screenknowledge: action.data,
        screenknowledge_loading: false,
      };
    case 'GET_SCREEN_ARTICLE_LOADING':
      return {
        ...state,
        screenarticle_loading: true,
      };
    case 'GET_SCREEN_ARTICLE':
      return {
        ...state,
        screenarticle: action.data,
        screenarticle_loading: false,
      };

    // CONTENT / CLICKS
    case 'SET_CLICK_LOADING_FALSE':
      return {
        ...state,
        clickcoupon_loading: false,
        clicktodo_loading: false,
        clickhome_loading: false,
        clickknowledge_loading: false,
        clickarticle_loading: false,
        clickrecipes_loading: false,
      };
    case 'GET_CLICK_COUPON_LOADING':
      return {
        ...state,
        clickcoupon_loading: true,
      };
    case 'GET_CLICK_COUPON':
      return {
        ...state,
        clickcoupon: action.data,
        clickcoupon_loading: false,
      };
    case 'GET_CLICK_TODO_LOADING':
      return {
        ...state,
        clicktodo_loading: true,
      };
    case 'GET_CLICK_TODO':
      return {
        ...state,
        clicktodo: action.data,
        clicktodo_loading: false,
      };
    case 'GET_CLICK_HOME_LOADING':
      return {
        ...state,
        clickhome_loading: true,
      };
    case 'GET_CLICK_HOME':
      return {
        ...state,
        clickhome: action.data,
        clickhome_loading: false,
      };
    case 'GET_CLICK_KNOWLEDGE_LOADING':
      return {
        ...state,
        clickknowledge_loading: true,
      };
    case 'GET_CLICK_KNOWLEDGE':
      return {
        ...state,
        clickknowledge: action.data,
        clickknowledge_loading: false,
      };
    case 'GET_CLICK_ARTICLE_LOADING':
      return {
        ...state,
        clickarticle_loading: true,
      };
    case 'GET_CLICK_ARTICLE':
      return {
        ...state,
        clickarticle: action.data,
        clickarticle_loading: false,
      };
    case 'GET_CLICK_RECIPE_LOADING':
      return {
        ...state,
        clickrecipe_loading: true,
      };
    case 'GET_CLICK_RECIPE':
      return {
        ...state,
        clickrecipe: action.data,
        clickrecipe_loading: false,
      };

    // CONTENT / POPUP
    case 'GET_POPUP_ANALYTICS_LOADING':
      return {
        ...state,
        popup_analytics_loading: true,
      };
    case 'GET_POPUP_ANALYTICS':
      return {
        ...state,
        popup_analytics: action.data,
        popup_analytics_loading: false,
      };
    case 'GET_POPUP_DATE_ANALYTICS':
      return {
        ...state,
        popup_date_analytics: action.data,
      };
    case 'GET_COMPANY_ANALYTICS_LOADING':
      return {
        ...state,
        company_loading: true,
        company_loaded: false,
      };
    case 'GET_COMPANY_ANALYTICS':
      return {
        ...state,
        company_loading: false,
        company_loaded: true,
        company: action.data,
      };
    case 'GET_COMPANY_LIST':
      return {
        ...state,
        companylist: action.data,
      };
    case 'COMPANY_LOADED_FALSE':
      return {
        ...state,
        company_loaded: false,
      };

    // CONTENT / NOTIFICATION CENTER
    case 'GET_NOTIFICATION_CENTER_ANALYTICS_LOADING':
      return {
        ...state,
        notification_center_analytics_loading: true,
      };
    case 'GET_NOTIFICATION_CENTER_ANALYTICS':
      return {
        ...state,
        notification_center_analytics: action.data,
        notification_center_analytics_loading: false,
      };

    case 'GET_USER_TODOS':
      return {
        ...state,
        user_todos: action.data,
        user_todos_loading: false,
      };
    case 'GET_USER_TODOS_LOADING':
      return {
        ...state,
        user_todos_loading: true,
      };

    case 'GET_PREMIUM_TRANSACTIONS':
      return {
        ...state,
        premium_transactions: action.data,
        premium_transactions_loading: false,
      };
    case 'GET_PREMIUM_TRANSACTIONS_LOADING':
      return {
        ...state,
        premium_transactions_loading: true,
      };

    default:
      return state;
  }
};

export default analytics;
