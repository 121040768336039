/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { getUserAll } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import DateSelector from '../../DateSelector';
import Spinner from '../../Spinner';
import { CSVLink } from 'react-csv';

const UserAll = () => {
  const dispatch = useDispatch();
  const allLoading = useSelector((state) => state.analytics.userall_loading);
  const pgDataSharingAcceptedUsers = useSelector(
    (state) => state.analytics.pgDataSharingAcceptedUsers
  );
  const userAll = useSelector((state) => state.analytics.userall);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  const days = userAll.map((day, index) => {
    return {
      ...day,
      growth: userAll[index + 1] ? day.users - userAll[index + 1].users : 0,
    };
  });

  const search = () => {
    dispatch(getUserAll({ before: endDate, after: startDate }));
  };

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      dispatch(getUserAll({ before: endDate, after: startDate }));
    }
  }, []);

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Number of all users', key: 'users' },
    { label: 'User growth', key: 'growth' },
    { label: 'New users (registration)', key: 'newUsers' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />

      <div className="flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>

        {!allLoading && days.length !== 0 && (
          <CSVLink
            className="bg-orange text-white p-2 rounded"
            filename={`UserAnalytics-ALL-${formatDate(startDate)}--${formatDate(endDate)}.csv`}
            data={days}
            headers={headers}
          >
            Download this table
          </CSVLink>
        )}
      </div>

      <div className="rounded-md bg-lightorange my-4 p-4">
        <div className="text-sm text-yellow-700">
          <p>
            <strong>Count of users who have accepted P&G Data Sharing consent:</strong>{' '}
            {pgDataSharingAcceptedUsers}
          </p>
        </div>
      </div>

      <div className="rounded-md bg-lightorange my-4 p-4">
        <h3 className="text-sm font-medium text-yellow-800">Legend</h3>
        <div className="mt-2 text-sm text-yellow-700">
          <p>
            <strong>User growth:</strong> gross change in number of users, including new
            registrations and deletions.
            <br />
            <strong>New users (registration):</strong> number of users registered on the given date.
          </p>
        </div>
      </div>

      <div className="mx-12">
        {allLoading && <Spinner />}
        {!allLoading && days.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number of all users</th>
                  <th>User growth</th>
                  <th>New users (registration)</th>
                </tr>
              </thead>
              <tbody>
                {days.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.users}</td>
                      <td>{item.growth}</td>
                      <td>{item.newUsers}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAll;
