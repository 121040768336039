import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/Dropdown';
import { getAllData, update, create } from '../../redux/actions/api';
import { openDeleteModal } from '../../redux/actions/state';
import Checkbox from '../../components/Checkbox';
import ImageUp from '../../components/ImageUp';
import FormForm from '../../components/forms/FormForm';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import NewButton from '../../components/NewButton';
import CustomButton from '../../components/CustomButton';
import TextField from '../../components/TextField';
import HTMLEditor from '../../components/HTMLEditor';
import MarkdownEditor from '../../components/MarkdownEditor';
import { sortByVisibilityAndOrder } from '../../utils/sort';
import { isBetweenPublishDateRange, validateStartEndDates } from '../../utils/date';

const Knowledge = (props) => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);
  const deleteType = useSelector((state) => state.state.deleteType);
  const companyList = useSelector((state) => state.analytics.companylist);

  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [tab, setTab] = useState('');
  const [week, setWeek] = useState('');
  const [newCreate, setNew] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});
  const [selectedItem3, setSelectedItem3] = useState({});
  const [mdContent, setMdContent] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(apidata).length === 0) {
        await dispatch(getAllData());
      }
    };

    fetchData().catch(console.error);
    setSelectedItem({});
    setSelectedItem2({});
    setSelectedItem3({});
    setTab(null);
    setWeek(null);
  }, [dispatch, apidata]);

  useEffect(() => {
    const category = data.find((cat) => cat.id === props.categoryId);
    if (category) {
      onClick(category);
    }
  }, [data, props.categoryId]);

  useEffect(() => {
    if (Object.keys(apidata).length > 0) {
      const sortedArray = apidata.knowledge.sort(sortByVisibilityAndOrder());
      setData(sortedArray);
      setTab(null);
      setWeek(null);
    }
    if (deleteType === 'knowledge-category') {
      setWeek(null);
      setNew(false);
      setSelectedItem3({});
      setSelectedItem2({});
      setSelectedItem({});
      setMdContent('');
      setHtmlContent('');
    }
    if (deleteType === 'knowledge-article') {
      setWeek(null);
      setNew(false);
      setSelectedItem3({});
      setSelectedItem2({});
      setSelectedItem({});
      setMdContent('');
      setHtmlContent('');
    }
  }, [apidata, deleteType]);

  const save = () => {
    const selected = selectedItem;
    const url = '/knowledge/' + selectedItem.id;
    selected.summary = mdContent;
    selected.summary_html = htmlContent;
    dispatch(update(selected, url));
    setSelectedItem3({});
    setSelectedItem2({});
    setSelectedItem({});
    setMdContent('');
    setHtmlContent('');
    setData([]);
  };

  const save2 = () => {
    const selected = selectedItem2;
    selected.article = mdContent;
    selected.article_html = htmlContent;
    const url = '/knowledge/updatedata/' + selectedItem.id;

    const dateValidationResult = validateStartEndDates(selected);

    if (!dateValidationResult.valid) return alert(dateValidationResult.error);

    dispatch(update(selected, url));
    setSelectedItem3({});
    setSelectedItem2({});
    setSelectedItem({});
    setMdContent('');
    setHtmlContent('');
    setData([]);
  };

  const save3 = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    const selectedend = selectedItem;
    selected2.data.forEach((e, i) => {
      if (e.id === selected.id) {
        selected2.data[i] = selected;
      }
    });
    selectedend.headers.forEach((e, i) => {
      if (e.id === selected2.id) {
        selectedend.headers[i] = selected2;
      }
    });

    const url = `/knowledge/${selectedItem.id}`;
    dispatch(update(selectedend, url));
    setSelectedItem3({});
    setSelectedItem2({});
    setSelectedItem({});
    setMdContent('');
    setHtmlContent('');
    setData([]);
  };

  const createCategory = () => {
    const selected = selectedItem;
    selected.id = Date.now().toString();
    selected.summary = mdContent;
    selected.summary_html = htmlContent;
    const url = '/knowledge/';
    dispatch(create(selected, url));
    setNew(false);
    setType('');
    setSelectedItem3({});
    setSelectedItem2({});
    setSelectedItem({});
    setMdContent('');
    setHtmlContent('');
    setData([]);
  };

  const createArticle = () => {
    const selected = selectedItem2;
    selected.id = Date.now().toString();
    selected.article = mdContent;
    selected.article_html = htmlContent;
    const url = '/knowledge/data/' + selectedItem.id;
    dispatch(update(selected, url));
    setSelectedItem3({});
    setSelectedItem2({});
    setSelectedItem({});
    setMdContent('');
    setHtmlContent('');
    setNew(false);
    setType('');
    setData([]);
  };

  const createItems = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    const selectedend = selectedItem;
    selected.id = Date.now().toString();
    selectedend.headers.forEach((e, i) => {
      if (e.id === selected2.id) {
        selectedend.headers[i].data = [...selectedend.headers[i].data, selected];
      }
    });
    const url = `/knowledge/${selectedItem.id}`;
    dispatch(update(selectedend, url));
  };

  const deleteCategory = () => {
    const selected = selectedItem;
    const url = '/knowledge/' + selectedItem.id;
    const type = 'knowledge-category';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = '/knowledge/removedata/' + selectedItem.id;
    const type = 'knowledge-article';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderLinksInArticle = () => {
    const deleteLink = (toDelete) => {
      const confirmed = window.confirm('Are you sure you want to delete this link?');
      if (confirmed) {
        setSelectedItem2({
          ...selectedItem2,
          links: selectedItem2.links.filter((_, index) => index !== toDelete),
        });
      }
    };

    return (
      <div>
        {selectedItem2?.links?.map((link, index) => {
          return (
            <div key={`article-link-${index}`}>
              <div className="flex justify-between items-center">
                <p className="font-bold">{index + 1}. link</p>
                <CustomButton red onClick={() => deleteLink(index)}>
                  Delete link
                </CustomButton>
              </div>
              <TextField label="Link type">
                <Dropdown
                  value={selectedItem2.links[index].type}
                  options={[
                    { value: 'link', label: 'Text link' },
                    { value: 'banner', label: 'Banner' },
                    { value: 'tracking_pixel', label: 'Impression URL / Tracking pixel' },
                  ]}
                  onChange={({ value }) => {
                    const selectedItemNew = { ...selectedItem2 };
                    selectedItemNew.links[index].type = value;
                    setSelectedItem2(selectedItemNew);
                  }}
                  className={'w-2/3'}
                />
              </TextField>
              <TextField
                type="text"
                label="Link"
                name={`link[${index}]`}
                value={selectedItem2.links[index].link}
                onChange={(event) => {
                  const selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.links[index].link = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField label="Company name">
                <Dropdown
                  value={selectedItem2.links[index].companyname}
                  options={[{ value: '', label: 'None' }, ...companyList]}
                  onChange={(value) => {
                    const selectedItemNew = { ...selectedItem2 };
                    selectedItemNew.links[index].companyname = value.value;
                    setSelectedItem2(selectedItemNew);
                  }}
                  className={'w-2/3'}
                />
              </TextField>
              <TextField
                type="text"
                label="Product / Link text"
                name={`product[${index}]`}
                value={selectedItem2.links[index].product}
                onChange={(event) => {
                  const selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.links[index].product = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              {selectedItem2.links[index].type === 'tracking_pixel' ? (
                <>
                  <TextField
                    type="text"
                    label="Start date"
                    name={`start_date[${index}]`}
                    value={selectedItem2.links[index].start_date}
                    onChange={(event) => {
                      const selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.links[index].start_date = event.target.value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                  <TextField
                    type="text"
                    label="End date"
                    name={`end_date[${index}]`}
                    value={selectedItem2.links[index].end_date}
                    onChange={(event) => {
                      const selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.links[index].end_date = event.target.value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                </>
              ) : null}
            </div>
          );
        })}

        <button
          type={'button'}
          className="rounded px-4 py-2 m-2 text-black bg-lightorange w-32"
          onClick={() => {
            const selectedItemNew = { ...selectedItem2 };
            selectedItemNew.links.push({
              companyname: '',
              product: '',
              link: '',
              type: 'link',
              start_date: '',
              end_date: '',
            });
            setSelectedItem2(selectedItemNew);
          }}
        >
          Add link
        </button>
      </div>
    );
  };

  const deeplink = useMemo(() => {
    if (newCreate) return '';

    return selectedItem2.id
      ? `preghello://knowledge/articles/${selectedItem.id}/${selectedItem2.id}`
      : `preghello://knowledge/details/${selectedItem.id}`;
  }, [newCreate, selectedItem.id, selectedItem2.id]);

  const onClick = (e) => {
    const datatorender = {
      id: e.id,
      img: e.img ? e.img : '',
      order: e.order ? e.order : '',
      companyname: e.companyname ? e.companyname : '',
      product: e.product ? e.product : '',
      summary: e.summary ? e.summary : '',
      title: e.title ? e.title : '',
      visible: e.visible ? e.visible : 'true',
      headers: e.headers ? e.headers : [],
      articles: e.articles ? e.articles : [],
      abtest: e.abtest ? e.abtest : 'false',
      secondtitle: e.secondtitle ? e.secondtitle : '',
      secondimg: e.secondimg ? e.secondimg : '',
    };

    setMdContent(e.summary ? e.summary : '');
    setHtmlContent(e.summary_html ? e.summary_html : '');
    setTab(e.id);
    setWeek(null);
    setNew(false);
    setSelectedItem(datatorender);
    setSelectedItem2({});
    setSelectedItem3({});
    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const content = (
    <>
      <div>
        {!props.categoryId && (
          <div className="mx-12 flex flex-wrap pt-12">
            <NewButton
              onClick={() => {
                setNew(true);
                setType('first');
                setSelectedItem({
                  id: '',
                  img: '',
                  order: '',
                  companyname: '',
                  product: '',
                  summary: '',
                  title: '',
                  visible: 'false',
                  articles: [],
                  abtest: 'false',
                  secondtitle: '',
                  secondimg: '',
                });
                setMdContent('');
                setHtmlContent('');
                setLoading(false);
                setTimeout(() => {
                  setLoading(true);
                }, 500);
              }}
              title="Add Category"
            />
            {data &&
              data
                .filter((category) => {
                  if (props.excludedCategoryIds?.length > 0) {
                    return !props.excludedCategoryIds.includes(category.id);
                  } else {
                    return true;
                  }
                })
                .map((e, i) => {
                  return (
                    <MenuButton
                      key={i}
                      onClick={() => onClick(e)}
                      active={tab === e.id}
                      visible={e.visible ? e.visible : 'true'}
                      title={e.title + ' - ' + e.order}
                    />
                  );
                })}
          </div>
        )}
      </div>
      <div className="my-4 flex flex-col justify-center">
        {data && data.length > 0 && Object.keys(selectedItem).length > 0 && (
          <div className="mx-12 flex flex-wrap">
            {selectedItem?.articles?.length === 0 && selectedItem?.headers?.length > 0 ? null : (
              <NewButton
                onClick={() => {
                  setNew(true);
                  setType('second');
                  setSelectedItem2({
                    ad: '',
                    ad_img: '',
                    id: '',
                    article: '',
                    img: '',
                    length: '',
                    order: '',
                    title: '',
                    visible: 'true',
                    abtest: 'false',
                    links: [],
                    secondtitle: '',
                    secondimg: '',
                    form: '',
                    formId: '',
                  });
                  setMdContent('');
                  setHtmlContent('');
                  setLoading(false);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                title="Add Article"
              />
            )}
            {newCreate && type === 'first'
              ? null
              : data &&
                data.length !== 0 &&
                data.find((item) => item.id === tab) &&
                data
                  .find((item) => item.id === tab)
                  .articles.sort(sortByVisibilityAndOrder())
                  .map((value, index) => (
                    <MenuButton
                      key={index}
                      onClick={() => {
                        const datatorender = {
                          id: value.id,
                          article: value.article ? value.article : '',
                          ad: value.ad ? value.ad : '',
                          ad_img: value.ad_img ? value.ad_img : '',
                          img: value.img ? value.img : '',
                          length: value.length ? value.length : '',
                          order: value.order ? value.order : '',
                          title: value.title ? value.title : '',
                          visible: value.visible ? value.visible : 'true',
                          abtest: value.abtest ? value.abtest : 'false',
                          links: value.links ? value.links : [],
                          secondtitle: value.secondtitle ? value.secondtitle : '',
                          secondimg: value.secondimg ? value.secondimg : '',
                          form: value.form ? value.form : '',
                          formId: value.formId ? value.formId : '',
                          start_date: value.start_date || '',
                          end_date: value.end_date || '',
                        };
                        setWeek(index);
                        setMdContent(value.article ? value.article : '');
                        setHtmlContent(value.article_html ? value.article_html : '');
                        setNew(false);
                        setSelectedItem2(datatorender);
                        setLoading(false);
                        setTimeout(() => {
                          setLoading(true);
                        }, 500);
                      }}
                      active={week === index}
                      visible={
                        value.visible && isBetweenPublishDateRange(value)
                          ? value.visible
                          : isBetweenPublishDateRange(value)
                          ? 'true'
                          : 'false'
                      }
                      title={value.title + ' - ' + value.order}
                    />
                  ))}
            {newCreate && type === 'first'
              ? null
              : data &&
                data.length !== 0 &&
                data
                  .find((item) => item.id === tab)
                  ?.headers?.map((value, index) => (
                    <MenuButton
                      key={index}
                      onClick={() => {
                        setWeek(index);
                        setNew(false);
                        setSelectedItem2(data.find((item) => item.id === tab)?.headers[index]);
                        setLoading(false);
                        setTimeout(() => {
                          setLoading(true);
                        }, 500);
                      }}
                      active={week === index}
                      visible="true"
                      title={value.title}
                    />
                  ))}
          </div>
        )}
      </div>
      <div className="my-4 flex flex-col justify-center">
        {data &&
          data.length > 0 &&
          Object.keys(selectedItem2).length > 0 &&
          selectedItem2.data?.length > 0 && (
            <div className="w-full mx-12 flex flex-wrap">
              <NewButton
                onClick={() => {
                  setNew(true);
                  setType('third');
                  setSelectedItem3({
                    id: '',
                    title: '',
                    summary: '',
                  });
                  setLoading(false);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                title="Add Item"
              />
              {newCreate && type === 'first'
                ? null
                : selectedItem2.data.map((value, index) => (
                    <MenuButton
                      key={index}
                      onClick={() => {
                        const datatorender = {
                          id: value.id,
                          title: value.title,
                          summary: value.summary,
                        };
                        setNew(false);
                        setSelectedItem3(datatorender);
                        setLoading(false);
                        setTimeout(() => {
                          setLoading(true);
                        }, 500);
                      }}
                      active={selectedItem3?.id === value.id}
                      visible={'true'}
                      title={value.title}
                    />
                  ))}
            </div>
          )}
      </div>
      {
        // CATEGORY
        ((newCreate && type === 'first') ||
          (Object.keys(selectedItem).length > 0 &&
            Object.keys(selectedItem2).length === 0 &&
            !newCreate)) &&
          loading && (
            <div className="mx-12">
              <TextField type="text" label="Id" name="id" value={selectedItem.id} disabled />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
              <TextField
                type="text"
                label="Order"
                name="order"
                value={selectedItem.order}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.order = event.target.value;

                  setSelectedItem(selectedItemNew);
                }}
              />
              <TextField label="Company name">
                <Dropdown
                  value={selectedItem.companyname}
                  options={[{ value: '', label: 'None' }, ...companyList]}
                  onChange={(value) => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.companyname = value.value;
                    setSelectedItem(selectedItemNew);
                  }}
                  className={'w-2/3'}
                />
              </TextField>
              <TextField
                type="text"
                label="Product"
                name="product"
                value={selectedItem.product}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.product = event.target.value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <ImageUp
                title="Cover Image (854 x 480)"
                value={selectedItem.img}
                parentCallback={() => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.img = '';
                  setSelectedItem(selectedItemNew);
                }}
                getImg={(value) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.img = value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <HTMLEditor
                title="Summary (html)"
                defaultValue={htmlContent}
                onChange={(content) => {
                  setHtmlContent(content);
                }}
              />
              <MarkdownEditor
                title="Summary (markdown)"
                value={mdContent}
                onChange={({ text }) => {
                  setMdContent(text);
                }}
              />

              <div className="flex flex-row justify-center">
                <div className="flex flex-col items-start justify-start">
                  <Checkbox
                    name="Visibility"
                    selected={selectedItem.visible}
                    getValue={(data) => {
                      const selectedItemNew = { ...selectedItem };
                      selectedItemNew.visible = data;
                      setSelectedItem(selectedItemNew);
                    }}
                  />

                  <Checkbox
                    name="Ab test"
                    selected={selectedItem.abtest}
                    getValue={(data) => {
                      const selectedItemNew = { ...selectedItem };
                      selectedItemNew.abtest = data;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                </div>
              </div>
              {selectedItem.abtest === 'true' && (
                <div>
                  <TextField
                    type="text"
                    label="New (B) Title"
                    name="secondtitle"
                    value={selectedItem.secondtitle}
                    onChange={(event) => {
                      const selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondtitle = event.target.value;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                  <ImageUp
                    title="New (B) Cover Image (820 x 460)"
                    parentCallback={() => {
                      const selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondimg = '';
                      setSelectedItem(selectedItemNew);
                    }}
                    value={selectedItem.secondimg}
                    getImg={(value) => {
                      const selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondimg = value;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                </div>
              )}

              {newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomButton type="submit" className="w-1/4" onClick={() => createCategory()}>
                    Create
                  </CustomButton>
                </div>
              )}
              {!newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomButton
                    red
                    type="submit"
                    className="w-1/4"
                    onClick={() => deleteCategory()}
                  >
                    Delete
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton type="submit" className="w-1/4" onClick={() => save()}>
                    Save
                  </CustomButton>
                </div>
              )}
            </div>
          )
      }
      {
        // ARTICLE
        ((newCreate && type === 'second') ||
          (Object.keys(selectedItem2).length > 0 && !selectedItem2.data && !newCreate)) &&
          loading && (
            <div className="mx-12">
              <TextField type="text" label="Id" name="id" value={selectedItem2.id} disabled />
              <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem2.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Order"
                name="order"
                value={selectedItem2.order}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.order = event.target.value;

                  setSelectedItem2(selectedItemNew);
                }}
              />

              <TextField
                type="text"
                label="Length (should be a number (minutes))"
                name="length"
                value={selectedItem2.length}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.length = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />

              <TextField
                type="text"
                label="Start Date*"
                name="start_date"
                placeholder="2023-01-01"
                required
                value={selectedItem2.start_date}
                onChange={(event) => {
                  const selectedItem = { ...selectedItem2 };
                  selectedItem.start_date = event.target.value;
                  setSelectedItem2(selectedItem);
                }}
              />
              <TextField
                type="text"
                label="End Date*"
                name="end_date"
                placeholder="2023-01-02"
                required
                value={selectedItem2.end_date}
                onChange={(event) => {
                  const selectedItem = { ...selectedItem2 };
                  selectedItem.end_date = event.target.value;
                  setSelectedItem2(selectedItem);
                }}
              />

              <ImageUp
                title="Cover Image (820 x 460)"
                value={selectedItem2.img}
                parentCallback={() => {
                  const selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = '';
                  setSelectedItem2(selectedItemNew);
                }}
                getImg={(value) => {
                  const selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = value;
                  setSelectedItem2(selectedItemNew);
                }}
              />

              <HTMLEditor
                title="Article (html)"
                defaultValue={htmlContent}
                onChange={(content) => {
                  setHtmlContent(content);
                }}
              />

              <MarkdownEditor
                title="Article (markdown)"
                value={mdContent}
                onChange={({ text }) => {
                  setMdContent(text);
                }}
              />

              <strong className={'text-lg'}>Links in article</strong>
              {renderLinksInArticle()}

              <div className="flex flex-row justify-center">
                <div className="flex flex-col items-start justify-start">
                  <Checkbox
                    name="Visibility"
                    selected={selectedItem2.visible}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.visible = data;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                  <Checkbox
                    name="Ab test"
                    selected={selectedItem2.abtest}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.abtest = data;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                </div>
              </div>
              {selectedItem2.abtest === 'true' && (
                <div>
                  <TextField
                    type="text"
                    label="New (B) Title"
                    name="secondtitle"
                    value={selectedItem2.secondtitle}
                    onChange={(event) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondtitle = event.target.value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                  <ImageUp
                    title="New (B) Image (820 x 460)"
                    value={selectedItem2.secondimg}
                    parentCallback={() => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondimg = '';
                      setSelectedItem2(selectedItemNew);
                    }}
                    getImg={(value) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondimg = value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                </div>
              )}
              <Checkbox
                name="Form visible"
                selected={selectedItem2.form}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.form = data;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              {selectedItem2.form === 'true' && selectedItem2.id && (
                <FormForm
                  formtype="article"
                  sourceIdnow={selectedItem2.id}
                  categoryIdnow={selectedItem.id}
                  parentCallback={() => setSelectedItem2({})}
                  selectedItem={selectedItem2}
                />
              )}
              {selectedItem2.form === 'true' && !selectedItem2.id && (
                <div>Please first save this item</div>
              )}
              {newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => {
                      setSelectedItem2({});
                      setNew(false);
                      setType('');
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => type === 'second' && createArticle()}
                  >
                    Create
                  </CustomButton>
                </div>
              )}
              {!newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomButton red type="submit" className="w-20" onClick={() => deleteArticle()}>
                    Delete
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton type="submit" className="w-20" onClick={() => save2()}>
                    Save
                  </CustomButton>
                </div>
              )}
            </div>
          )
      }
      {
        // ITEM
        ((newCreate && type === 'third') ||
          (Object.keys(selectedItem3).length > 0 && !newCreate)) &&
          loading && (
            <div className="mx-12">
              <TextField type="text" label="Id" name="id" value={selectedItem3.id} disabled />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem3.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem3 };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem3(selectedItemNew);
                }}
              />

              <TextField
                type="text"
                label="Summary"
                name="summary"
                value={selectedItem3.summary}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem3 };
                  selectedItemNew.summary = event.target.value;
                  setSelectedItem3(selectedItemNew);
                }}
              />

              {newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => {
                      setSelectedItem3({});
                      setNew(false);
                      setType('');
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => type === 'third' && createItems()}
                  >
                    Create
                  </CustomButton>
                </div>
              )}
              {!newCreate && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton type="submit" className="w-20" onClick={() => save3()}>
                    Save
                  </CustomButton>
                </div>
              )}
            </div>
          )
      }
    </>
  );

  return props.onlyContent ? content : <Layout active="knowledge">{content}</Layout>;
};

export default Knowledge;
