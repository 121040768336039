import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import Knowledge from './Knowledge';

const Tools = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const { role } = useSelector((state) => state?.auth?.user);
  const isAdmin = role === 'admin';

  useEffect(() => {
    setTab('');
  }, [dispatch]);

  return (
    <Layout hideMenu active="premium-settings" style={{ marginLeft: 0 }}>
      <div className="flex flex-row mx-12 mt-8">
        {isAdmin && (
          <>
            <MenuButton
              onClick={() => {
                setTab('baby-first-aid');
              }}
              active={tab === 'baby-first-aid'}
              title="Baba elsősegély tanfolyam"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                setTab('prenatal-exercises');
              }}
              active={tab === 'prenatal-exercises'}
              title="Szülésre készítő torna"
              visible="true"
            />
          </>
        )}
      </div>
      {tab === 'baby-first-aid' && <Knowledge onlyContent categoryId={'1728811421198'} />}
      {tab === 'prenatal-exercises' && <Knowledge onlyContent categoryId={'1728811421216'} />}
    </Layout>
  );
};

export default Tools;
